.auth-bg {
    background-image: url("../../assets/inspiration-geometry.png");
}

.auth-layout {
    max-width: 400px;
    width: 100%;
    margin: 0 auto 0 auto;
    padding-top: 150px;

    h1,
    h2 {
        text-align: center;
        color: $main-color;
    }

    h1 {
        font-size: 70px;
        margin-bottom: 20px;
    }

    p {
        margin: 20px 0 50px 0;
        font-size: 14px;
        color: gray;
    }

    .sign {
        margin-top: 70px;
        text-align: center;
        color: gray;
        line-height: 1.4;
        font-size: 13px;

        .name {
            font-weight: 600;
        }
    }

    .error {
        text-align: center;
        padding: 20px 20px;
        margin: 20px 0;
        background-color: #ff3414;
        color: white;
    }
}

.blue-layout {
    .top {
        background-color: $main-blue;
        background-image: url("../../assets/inspiration-geometry.png");
        margin-bottom: 40px;
        color: #f5f5f5;

        .menubar {
            height: 80px;
            margin-bottom: 50px;
            display: flex;
            justify-content: space-between;

            .logo {
                padding: 26px 0;
                font-size: 25px;

                .locatio {
                    font-weight: 700;
                    display: inline-block;
                    padding-right: 10px;
                    color: $main-color;
                }

                .org {
                    display: inline-block;
                    border-left: 1px solid #e8e8eb;
                    color: #cecece;
                    padding-left: 10px;
                }
            }

            .menu {
                display: flex;

                .item {
                    margin-left: 40px;
                    line-height: 80px;

                    &.user {
                        line-height: inherit;
                        vertical-align: middle;

                        .avatar {
                            margin: 20px 0;
                            display: inline-block;
                            height: 45px;
                            width: 45px;
                            border-radius: 10%;
                            overflow: hidden;
                            vertical-align: middle;
                            background-color: white;

                            img {
                                height: 100%;
                            }
                        }

                        .info {
                            display: inline-block;
                            margin-left: 10px;
                            vertical-align: middle;

                            .name {
                                font-weight: 600;
                                font-size: 20px;
                            }

                            .nav-user {
                                display: flex;

                                .nav-user-item {
                                    margin-right: 10px;
                                    font-size: 14px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }

                @media screen and (max-width: 950px) {
                    flex-direction: column;
                    background-color: white;
                    width: 100%;
                    z-index: 10000;
                    box-shadow: 0 5px 5px #dcdada80;

                    position: fixed;
                    top: 80px;
                    left: 0;

                    &.mobile-close {
                        display: none;
                    }
                }

                &.mobile-open {
                    color: $text;
                }
            }

            .mobile-menu {
                display: flex;
                justify-content: center;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }

                @media screen and (min-width: 950px) {
                    display: none;
                }
            }
        }

        .title {
            font-size: 50px;
            font-weight: 700;
            padding: 20px 0;
        }
    }

    .footer .container {
        text-align: right;
        padding: 150px 15px 50px 15px;
        color: #9c9c9c;

        a {
            font-weight: 600;
        }
    }
}

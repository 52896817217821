.modal-frame {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(black, 0.2);
    z-index: 1000;
    display: flex;

    align-items: center;
    justify-content: center;

    .modal-box {
        max-width: 800px;
        width: 100%;
        min-height: 200px;
        max-height: 80vh;
        overflow-y: auto;
        padding: 50px 15px;

        background-color: white;

        position: relative;

        .close {
            position: absolute;
            top: 10px;
            right: 20px;

            height: 30px;
            width: 30px;

            background-image: url("../../assets/x.svg");
            background-size: cover;

            opacity: 0.2;
            cursor: pointer;

            &:hover {
                opacity: 0.3;
            }
        }

        @media screen and (min-width: 950px) {
            padding: 50px;
        }
    }
}

$main-color: #32d893;
$main-blue: #0b2e9a;
$shadow: 0 0 5px #dad6d6;
$text: #333333;

body {
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    background-color: #f5f6f9;

    font-family: "Source Sans Pro", sans-serif;
    color: $text;
}

.top-layout {
    background-image: url("../assets/inspiration-geometry.png");
}

a {
    text-decoration: none;
    color: inherit;
}

h1 {
    margin: 20px 0 0 0;
}

.container {
    max-width: 1300px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    margin: 0 auto;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

label.form-control {
    display: block;
    margin-bottom: 20px;

    .title {
        font-weight: 600;
    }

    input,
    textarea,
    select,
    .input-status {
        box-sizing: border-box;
        width: 100%;
        border: 1px solid #e0e0e0;
        outline: none;
        margin: 3px 0;
        padding: 10px 15px;
        background-color: #ececec;

        &::placeholder {
            color: rgba(black, 0.2);
        }

        &:focus {
            background-color: white;

            &::placeholder {
                color: rgba(black, 0.4);
            }
        }
    }

    .input-status.selectMore {
        .items {
            display: flex;
            flex-wrap: wrap;

            .item {
                padding: 5px 10px;
                margin: 0 5px;
                background-color: white;
                display: flex;
                align-items: center;

                img.rm {
                    height: 16px;
                    margin-left: 5px;

                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }

    .input-status.qr {
        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
            width: calc(100% - 40px);
            padding: 0;
            border: 0;

            &:focus {
                background-color: transparent;
            }
        }

        svg {
            height: 20px;

            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
        }
    }

    textarea {
        resize: vertical;
    }
}

.table-wrapper {
    overflow-x: auto;
    width: 100%;
}

.table {
    background-color: white;
    width: 100%;
    margin: 10px 0 50px 0;
    border-collapse: collapse;

    thead,
    tfoot {
        background-color: #f9fafb;

        th {
            text-align: left;
            text-transform: uppercase;
            font-weight: 500;

            .desc {
                color: #adadad;
            }
        }
    }

    tr {
        box-sizing: border-box;
        border: 1px solid #e5e7eb;
    }

    td,
    th {
        padding: 10px 40px;
    }

    .two-line {
        display: inline-block;
        vertical-align: middle;

        .light {
            color: #c5cad0;
        }
    }

    .avatar {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
    }
}

.gap {
    height: 80px;

    &.h-2 {
        height: 20px;
    }

    &.h-5 {
        height: 50px;
    }
}

.cols {
    display: flex;
    flex-wrap: wrap;

    &.cols-3 > * {
        width: calc(calc(100% - 60px) / 3);
        margin: 0 10px 10px 10px;
    }

    &.cols-2 > * {
        width: calc(calc(100% - 40px) / 2);
        margin: 0 10px 10px 10px;
    }
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.summary {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;

    .item {
        margin: 0 100px 20px 0;

        .title {
            color: rgba($text, 0.5);
        }
    }
}

.viewPerson,
.viewPlan,
.modalView {
    .name {
        border-bottom: 1px solid #dedede;
        padding-bottom: 15px;
        margin-bottom: 30px;

        svg {
            margin-right: 15px;
        }

        display: flex;
        align-items: center;
    }
}

.viewPlan {
    .map {
        height: 500px;
        background-color: gray;
    }

    .leaflet-interactive {
        transition: 0.5s;
    }
}

.mini-beaconView,
.mini-deviceView {
    .title {
        font-size: 12px;

        .name {
            font-size: 16px;
            font-weight: 700;
        }

        .key {
            color: gray;
        }
    }

    .summary-title {
        font-weight: 700;
    }

    p {
        margin: 0;
    }
}

.tools {
    text-align: right;

    .item {
        display: inline-block;
        margin-left: 20px;
        vertical-align: middle;
        cursor: pointer;

        img {
            display: inline-block;
            vertical-align: middle;
            height: 15px;
            margin-right: 6px;
        }

        &:hover {
            opacity: 0.8;
        }
    }
}

.edit-beacon-pos {
    .act-beacon {
        margin-top: 15px;
        background-color: #f5f6f9;
        padding: 15px 20px;

        h3 {
            margin-bottom: 0;
        }
    }
}

.addPlan-modal {
    .floors {
        margin: 40px 0;

        .floor {
            height: 60px;
            background-color: #e8e8e8;
            margin: 5px 0;
            position: relative;
            padding: 5px 20px;
            display: flex;
            align-items: center;

            .floor-img {
                height: 60px;
                max-width: 100px;
                background-color: white;
            }

            .name {
                display: inline-block;
                margin-left: 20px;
            }

            .close-btn {
                height: 30px;
                width: 30px;

                position: absolute;
                top: 10px;
                right: 20px;

                cursor: pointer;

                background-image: url("../assets/x.svg");
                background-size: cover;

                opacity: 0.2;
                cursor: pointer;

                &:hover {
                    opacity: 0.3;
                }
            }
        }
    }
}

.step-info {
    margin: 20px 0;
    padding: 40px 30px 30px 100px;
    background-color: white;
    box-shadow: 0 0 8px #00000012;
    position: relative;

    background-image: url("../assets/message.svg");
    background-repeat: no-repeat;
    background-size: 100px;
    background-position: 0px 10px;

    &:before {
        position: absolute;
        top: 20px;
        left: 100px;
        color: rgba(black, 0.3);

        content: "Tip!";
    }
}

.easy-button-button {
    img {
        width: 18px;
        margin: 5px 0;
    }
}

.timeline-wrapper {
    box-shadow: 0 0 5px #dad6d6;
    background-color: white;
    padding: 20px 40px 20px 40px;
    margin: 20px 0;

    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .sub-h1 {
            margin-left: 20px;
            display: inline-block;
            color: gray;
        }

        .select {
            display: inline-grid;
            grid-template-columns: auto auto auto;
            border: 1px solid lightgray;
            border-radius: 10px;
            overflow: hidden;

            .sel {
                select {
                    border: 0 transparent;
                    background-color: transparent;
                    padding: 0;
                    outline: transparent;
                    cursor: pointer;
                }

                border-left: 1px solid lightgray;
                border-right: 1px solid lightgray;

                &:hover {
                    background-color: rgba(lightgray, 0.5);
                }
            }

            * {
                padding: 5px 10px;
            }

            .prev, .next {
                cursor: pointer;
                font-weight: 700;

                &:hover {
                    background-color: rgba(lightgray, 0.5);
                }
            }

            .prev {
                font-size: 1.3em;
            }
        }
    }

    .timeline {
        display: grid;
        grid-template-columns: 250px auto;
        margin-top: 20px;

        .time {
            height: 20px;
            border-bottom: 1px solid lightgray;
        }

        .left {
            .item {
                padding: 10px;
                height: 65px;
                box-sizing: border-box;
                border-bottom: 1px solid transparent;

                .name {
                    font-size: 20px;
                    font-weight: 700;
                }

                .sub {
                    opacity: 0.5;
                }
            }

            .time {
                border-bottom: 1px solid transparent;
            }
        }

        .right {
            overflow-x: auto;
            background-color: #f7f7f7;
            padding-left: 25px;
            position: relative;

            .now {
                background-color: rgba(red, 0.3);
                height: 100%;
                width: 3px;
                position: absolute;
                top: 0; left: 0;
            }

            .time {
                display: flex;
                width: max-content;

                .item {
                    width: 50px;
                    border-left: 1px solid lightgray;
                    text-indent: 0px;
                    color: lightgray;
                    box-sizing: border-box;
                    text-indent: -50px;
                    text-align: center;
                    color: inherit;
                }
            }

            .activity {
                position: relative;
                height: 65px;
                box-sizing: border-box;

                .boxes {
                    display: flex;
                    width: max-content;
                    position: absolute;
                    top: 0;
                    left: 0;
                    box-sizing: border-box;

                    .box {
                        height: 65px;
                        box-sizing: border-box;
                        width: 50px;
                        border-left: 1px solid lightgray;
                        border-bottom: 1px solid lightgray;
                    }
                }

                .timeStamp {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    background-color: $main-color;
                    border-left: 1px solid #2ece8b;
                    box-sizing: border-box;

                    &:hover {
                        background-color: #2cec9c;
                    }
                }
            }
        }
    }
}

@import "components/loading";
@import "components/btn";
@import "components/modal";

@import "layout/nav";
@import "layout/page404";
@import "layout/auth";
@import "layout/blue";

@import "pages/index";
@import "pages/createOrg";
@import "pages/organization";
@import "pages/docs";
@import "pages/homepage";

.page-homepage {
    .btn.success {
        background-color: #00a560 !important;
    }

    .container {
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;
        padding: 0 15px;
        box-sizing: border-box;
    }

    .topline {
        position: absolute;
        top: 10px;
        left: 0;
        z-index: 200;
        width: 100%;

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;

            .logo {
                font-weight: 700;
                font-size: 40px;
                color: $main-color;
            }
        }
    }

    .welcome-slide {
        min-height: 500px;
        width: 100%;
        overflow: hidden;
        /*background-color: $main-color;*/
        background-color: $main-blue;
        background-image: url("../../assets/inspiration-geometry.png");

        .container {
            position: relative;
            height: 500px;

            .box {
                position: absolute;
                top: 180px;
                max-width: 600px;

                p {
                    max-width: 400px;
                    font-size: 25px;
                    color: #d4d4d4;
                }
            }

            /*
            .sprite {
                width: 192px;
                height: 256px;
                position: absolute;
                right: 25%;
                top: 100px;
                background-image: url("/img/homepage/sprite_walking-01.png");
                background-position: 0 0;
                animation: walk 0.65s steps(8) infinite;

                @keyframes walk {
                    100% { background-position: calc(-8 * 192px) 0; }
                }
            }*/

            .map {
                position: absolute;
                top: 120px;
                right: calc(25% - 160px);
                /*background-color: white;*/
                background-image: url("../../assets/plan.svg");
                width: 500px;
                height: 300px;

                .dot {
                    width: 100%;
                    height: 100%;

                    img {
                        position: absolute;
                        top: 107px;
                        left: 320px;
                        animation: walk 180s infinite linear;

                        @keyframes walk {
                            0% {
                                top: 40px;
                                left: 370px;
                            }
                            10% {
                                top: 107px;
                                left: 350px;
                            }
                            13% {
                                top: 107px;
                                left: 320px;
                            }
                            20% {
                                top: 201px;
                                left: 330px;
                            }
                            27% {
                                top: 107px;
                                left: 320px;
                            }
                            35% {
                                top: 107px;
                                left: 205px;
                            }
                            47% {
                                top: 150px;
                                left: 205px;
                            }
                            58% {
                                top: 150px;
                                left: 145px;
                            }
                            60% {
                                top: 40px;
                                left: 145px;
                            }
                            65% {
                                top: 150px;
                                left: 145px;
                            }
                            70% {
                                top: 150px;
                                left: 205px;
                            }
                            75% {
                                top: 107px;
                                left: 205px;
                            }
                            85% {
                                top: 107px;
                                left: 330px;
                            }
                            100% {
                                top: 40px;
                                left: 370px;
                            }
                        }
                    }
                }

                @media screen and (max-width: 950px) {
                    opacity: 0.3;
                }
            }
        }
    }

    .features {
        width: 100%;
        margin-top: -60px;

        .container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        .feature {
            background-color: white;
            box-shadow: 0 0 12px 0 #00000040;
            box-sizing: border-box;
            height: 250px;
            width: 250px;
            margin: 20px;
            text-align: center;
            padding: 40px 20px;

            img {
                height: 100px;
            }

            h2 {
                margin: 15px 0 5px 0;
                font-size: 20px;
            }

            p {
                margin: 0;
            }
        }
    }

    .video {
        margin: 100px 0;
        text-align: center;

        h1 {
            margin: 20px 0;
        }

        iframe {
            width: 100%;
            height: 400px;
        }

        @media screen and (min-width: 500px){
            iframe {
                height: 500px;
            }
        }

        @media screen and (min-width: 700px){
            iframe {
                max-width: 800px;
                height: 500px;
            }
        }
    }

    .maturita {
        margin: 100px 0;

        .cols-2 {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            .left,
            .right {
                margin-bottom: 80px;
            }
        }

        p {
            font-size: 20px;
            max-width: 500px;
        }

        .sign {
            margin-bottom: 20px;

            .title {
                font-weight: 500;
            }

            .name {
                font-weight: 800;
                font-size: 25px;
            }
        }
    }

    .setup {
        background-color: white;
        box-shadow: 0 0 12px 0 #00000040;
        box-sizing: border-box;
        margin: 20px;
        padding: 40px 20px;

        .steps {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 auto;
            width: 100%;

            .step {
                margin-right: 35px;
                margin-bottom: 15px;
                cursor: pointer;

                .num {
                    background-color: #949494;
                    color: white;
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    display: inline-block;
                    margin-right: 5px;
                }

                .text {
                    display: inline-block;
                }

                &.active {
                    .num {
                        background-color: #00a560;
                    }

                    .text {
                        color: inherit;
                    }
                }
            }
        }

        .content {
            padding: 50px;

            .step {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                @media screen and (min-width: 1150px) {
                    flex-direction: row;
                }

                .text {
                    margin-bottom: 80px;

                    p {
                        max-width: 400px;
                        line-height: 1.5;
                    }
                }

                .images {
                    max-width: 500px;
                    width: 100%;

                    .slide {
                        background-color: $main-blue;
                        padding: 25px 0;
                    }

                    .desc {
                        margin-top: 10px;
                    }

                    .btns {
                        margin-top: 10px;
                        text-align: right;
                    }

                    &.mw img {
                        max-width: 500px;
                        width: 100%;
                    }
                }
            }
        }
    }

    .footer .container {
        text-align: right;
        padding: 150px 15px 50px 15px;
        color: #9c9c9c;

        a {
            font-weight: 600;
        }
    }
}
